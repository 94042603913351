import {KpiMetric} from '@/app/entities/KpiMetric';

export class NumberUtils {

    static formatMetricValue(v, linkedKpiMetric: KpiMetric) {
        if (isNaN(v)) {
            return v;
        }
        // Formatting based on https://objectivepartners.atlassian.net/wiki/spaces/SDE/pages/2830073859/Standards+for+Number+Formatting#Currency
        if (linkedKpiMetric.isMonetary) {
            const absoluteValue = Math.abs(v);
            if (absoluteValue >= 1000) {
                return Number(parseFloat(v).toFixed(0));
            } else if (absoluteValue < 10) {
                return Number(parseFloat(v).toFixed(4));
            }
            return Number(parseFloat(v).toFixed(2));
        }
        return Number(parseFloat(v).toFixed(linkedKpiMetric.decimalPlaces));
    }

    static formatNumber(value: number | string, isCurrency: boolean = false, decimals?: number): string {
        const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;
        if (isNaN(numericValue)) {
            return value + '';
        }

        const absValue = Math.abs(numericValue);
        const sign = numericValue < 0 ? '-' : '';

        if (absValue >= 1000) {
            value = absValue.toFixed(decimals === undefined ? (isCurrency ? 2 : 0) : decimals);
            value = this.addThousandsSeparator(value);
        } else if (absValue >= 1) {
            value = absValue.toFixed(decimals === undefined ? 2 : decimals);
        } else {
            value = this.removeTrailingZeros(absValue.toFixed(decimals === undefined ? 4 : decimals), decimals);
        }
        return sign + value;
    }

    static removeTrailingZeros(number, minDecimals = 2) {
        let numberString = number.toString();

        if (numberString.includes('.')) {
            let decimalPart = numberString.split('.')[1];
            let trimmedDecimalPart = decimalPart.substring(0, minDecimals) + decimalPart.substring(minDecimals).replace(/0+$/, '');
            numberString = numberString.split('.')[0] + '.' + trimmedDecimalPart;
        }

        return numberString;
    }

    static addThousandsSeparator(value) {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    public static roundToTwoDecimalPlaces(num: number) {
        return (Math.round((num + Number.EPSILON) * 100) / 100);
    }

    public static calculatePercentageDifference(
        originalValue: number,
        optimalValue: number,
        fractionDigits?: number,
    ): number {
        if (!optimalValue) {
            return 0;
        }
        if (originalValue === 0) {
            return 100;
        }

        const difference = ((optimalValue - originalValue) / originalValue) * 100;

        // Apply optional rounding only if requested
        const result = typeof fractionDigits === 'number'
            ? +difference.toFixed(fractionDigits)
            : difference;
        return isNaN(result) ? null : result;
    }
}