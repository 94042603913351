export class TranslationService {

    static translations;

    constructor() {
    }

    static initTranslations(translations) {
        this.translations = translations;
    }

    /**
     * translate function, returns the translation from the state or the input value if not found
     * @param value
     */
    static translate(value: string) {
        const translation = value;

        if (this.translations && this.translations[value]) {
            return this.translations[value];
        }

        return translation;
    }

    static getTranslations() {
        return this.translations;
    }
}


