import {Pipe, PipeTransform} from '@angular/core';

/*
 * Sort Array:
 * by 'asc' or 'desc', which stands for ascending or 'descending' order.
 * The sorting can be case-sensitive or case-insensitive.
 * Usage:
 *   Array | alphabeticalOrder: order: <string> : caseSensitive: <boolean>
 * Example:
 *   {{ <Array> | alphabeticalOrder: 'desc' : false }}
 *   sorts array alphabetically in descending order, case-insensitive
 */

@Pipe({name: 'alphabeticalOrder'})
export class AlphabeticalOrderPipe implements PipeTransform {
    transform(value: any[], order?: string, caseSensitive: boolean = true): any[] {
        if (!value || value.length <= 1) {
            return value;
        }
        const sortFunction = caseSensitive
            ? (a: string, b: string) => a.localeCompare(b)
            : (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());
        switch (order) {
            case 'desc':
                return value.sort(sortFunction).reverse();
            default:
                return value.sort(sortFunction);
        }
    }
}
