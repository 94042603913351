<div id="selectedClient" style="position: relative;">

    <div #clientSelector (click)="toggleMenu()" [class.client-selector]="filteredClients.length > 1">
        <ng-container *ngIf="clientLogo?.length">
            <img alt="{{selectedClient}}" id="clientLogo" src="{{clientLogo}}"/>
        </ng-container>
        <h5 *ngIf="!clientLogo?.length"> {{ selectedClient }} </h5>
        <div *ngIf="!hideMenuDropdown && filteredClients.length > 1" [class.for-img]="clientLogo?.length" class="dropdown-toggle"></div>
    </div>

    <div #clientDropdown [style.display]="isMenuHidden ? 'none' : 'block'" class="client-dropdown">
        <ul>
            <li (click)="changeClient(client)"
                *ngFor="let client of filteredClients | alphabeticalOrder: 'asc': true" 
                class="ghost {{ selectedClient === client ? 'active' : ''}}">{{ client }}
            </li>
        </ul>
    </div>

</div>
<div class="clearfix"></div>